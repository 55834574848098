
import { Vue, Options } from 'vue-class-component'
import axios from '@/plugins/axios'
import { ElMessageBox } from 'element-plus'
import { ElMessage } from 'element-plus'
import braintree from 'braintree-web'
import { h } from 'vue'

interface Result {
  list: []
  token: ''
  status: boolean
}

interface Res {
  status: boolean
  message: string
}
type FlowType = 'checkout' | 'vault' // Or other allowed values

@Options({
  components: {}
})
export default class AccountPayment extends Vue {
  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }
  ListCards = []
  dialogFormVisible = false
  braintreeToken = ''
  isDefaultCard = false
  clientInstance: any
  hostedInstance: any
  paypalInstance: any
  paypalCheckoutInstance: any
  agree_service = false
  isShowWarning = true

  async created() {
    const mainUserID = this.$store.state.auth.dataLogin.id
    const selectedUserId = this.$store.state.auth?.accountSelected?.owner

    if (!selectedUserId) {
      this.isShowWarning = false
    } else {
      this.isShowWarning = mainUserID == selectedUserId ? false : true
    }

    if (!this.isShowWarning) {
      await this.getListCards()
      await this.getBraintreeToken()
      await this.addCardToBraintree()
    }
  }
  async getListCards() {
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Result
    res = await axios.get('user/list-of-payment-card?type=list', {
      headers: this.headers,
      params: {
        account: localStorage.getItem('account')
      }
    })
    if (res) {
      this.ListCards = res.list
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  async getBraintreeToken() {
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Result
    res = await axios.get('user/list-of-payment-card?type=token', {
      headers: this.headers,
      params: {
        account: localStorage.getItem('account')
      }
    })
    if (res) {
      this.braintreeToken = res.token
      // await this.$store.dispatch("auth/setBraitreeToken", res.token);
    }
    this.clientInstance = await braintree.client
      .create({
        authorization: this.braintreeToken
      })
      .catch((err) => {
        console.log(err)
        return false
      })
    console.log('this.clientInstance', this.clientInstance)
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  async addNewCardClick() {
    await this.$store.dispatch('setLoading', true, { root: true })

    if (!this.braintreeToken) {
      await this.getBraintreeToken()
    }
    this.isDefaultCard = false
    this.dialogFormVisible = true
    await this.initHostedFields()

    await this.$store.dispatch('setLoading', false, { root: true })
  }

  async initHostedFields() {
    await braintree.hostedFields.create(
      {
        client: this.clientInstance,
        styles: {
          input: {
            'font-size': '14pt',
            color: '#3A3A3A'
          },
          '.number': {
            'font-family': 'monospace'
          },
          '.valid': {
            color: 'black'
          }
        },
        fields: {
          cardholderName: {
            container: '#cardholder-name',
            placeholder: 'Please input'
          },
          number: {
            container: '#card-number',
            placeholder: '•••• •••• •••• ••••'
          },
          cvv: {
            container: '#cvv',
            placeholder: '•••'
          },
          expirationDate: {
            container: '#expiration-date',
            placeholder: 'MM / YYYY'
          }
        }
      },
      (hostedFieldsErr, hostedFieldsInstance) => {
        console.log(hostedFieldsErr)
        this.hostedInstance = hostedFieldsInstance
      }
    )
  }
  async destroyHostedFields(done: boolean) {
    this.hostedInstance.teardown(function (teardownErr: any) {
      if (teardownErr) {
        console.error(teardownErr)
      }
    })
    this.agree_service = false
  }
  async deleteCard(token: any) {
    await this.$store.dispatch('setLoading', true, { root: true })

    await axios
      .delete('user/delete-payment-card', {
        params: { paymentCardId: token },
        headers: this.headers
      })
      .then(function (res) {
        if (res.status) {
          ElMessage.success('Remove successfully !!!')
        } else ElMessage.error('Something wrong !!!')
      })
      .catch(function (error) {
        console.log(error)
        ElMessage.error('Something wrong !!!')
      })
    await this.getListCards()
    await this.$store.dispatch('setLoading', false, { root: true })
  }

  async submitForm() {
    if (!this.agree_service) {
      this.$message.warning(
        'Please accept License Agreement and our Privacy Policy'
      )
      return
    }
    await this.$store.dispatch('setLoading', true, { root: true })
    await this.updateCard()
      .then(async () => {
        await this.getListCards()
      })
      .catch((err) => console.log('[ERROR] Invalid card type'))

    // await axios.get("user/list-of-payment-card?type=list", {
    //   headers: this.headers,
    //   params: {
    //     account: localStorage.getItem("account"),
    //   },
    // });
    await this.$store.dispatch('setLoading', false, { root: true })
    this.dialogFormVisible = false
    this.agree_service = false
  }
  async updateCard() {
    return new Promise((resolve, reject) => {
      this.hostedInstance.tokenize(
        {
          vault: false
        },
        async function (tokenizeErr: any, payload: any) {
          if (tokenizeErr) {
            if (tokenizeErr.code === 'HOSTED_FIELDS_FIELDS_INVALID') {
              console.error(tokenizeErr)
              let invalidFieldKeys = tokenizeErr.details?.invalidFieldKeys || []
              if (invalidFieldKeys.length) {
                ElMessage({
                  type: 'error',
                  message: h(
                    'p',
                    { style: 'font-size: 16px; color: red;' },
                    tokenizeErr +
                      `[${invalidFieldKeys.map((k: any) => k.toUpperCase())}]`
                  )
                })
              }
            } else {
              console.error(tokenizeErr)
              ElMessage.error(tokenizeErr)
            }
            return reject({})
          }
          let cardType = payload?.details?.cardType
          cardType = (cardType || '').toLowerCase()
          if (!['visa', 'mastercard'].includes(cardType)) {
            ElMessage({
              type: 'error',
              message: h(
                'p',
                { style: 'font-size: 16px; color: red;' },
                'Unfortunately your card type is not supported. Please add Visa or Master card.'
              )
            })
            return reject({})
          } else {
            await axios
              .put(
                'user/update-card',
                {
                  nonce: payload.nonce,
                  type: 'new',
                  cardholder_name: payload.details.cardholderName
                },
                {
                  headers: {
                    'token-user': localStorage.getItem('tokenUser'),
                    account: localStorage.getItem('account')
                  }
                }
              )
              .then(function (res) {
                if (res.status) {
                  ElMessage({
                    type: 'success',
                    message: h(
                      'p',
                      { style: 'font-size: 16px; color: green;' },
                      'New card added successfully !!!'
                    )
                  })
                  return resolve({})
                } else {
                  ElMessage({
                    type: 'error',
                    message: h(
                      'p',
                      { style: 'font-size: 16px; color: red;' },
                      'Something wrong !!!'
                    )
                  })
                  return reject({})
                }
              })
              .catch(function (error) {
                console.log(error)
                ElMessage({
                  type: 'error',
                  message: h(
                    'p',
                    { style: 'font-size: 16px; color: red;' },
                    'Something wrong !!!'
                  )
                })
                return reject({})
              })
          }
        }
      )
    })
  }
  onDelete(token: any) {
    ElMessageBox.confirm(
      'Are you sure you want to delete the selected payment card? The card will be deleted immediately. You can’t undo this action.',
      'Warning',
      {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }
    )
      .then(() => {
        this.deleteCard(token)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  async clickChangeCardDefault(token: any) {
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Res
    res = await axios.put('user/update-card', {
      token: token,
      type: 'update',
      is_default_card: true,
      headers: this.headers
    })

    if (res.status) {
      ElMessage({
        type: 'success',
        message: h(
          'p',
          { style: 'font-size: 16px; color: green;' },
          'Default card has been updated !!!'
        )
      })
    } else {
      ElMessage.error(res.message)
    }
    await this.getListCards()
    await this.$store.dispatch('setLoading', false, { root: true })
  }

  async addCardToBraintree() {
    this.paypalInstance = await this.createPaypalInstance()
  }

  async createPaypalInstance() {
    return new Promise((resolve, reject) => {
      braintree.paypal.create(
        {
          client: this.clientInstance
        },
        function (createErr, paypalInstance) {
          if (createErr) {
            if (createErr.code === 'PAYPAL_BROWSER_NOT_SUPPORTED') {
              console.error('This browser is not supported.')
            } else {
              console.error('Error!', createErr)
            }
          }
          return resolve(paypalInstance)
        }
      )
    })
  }

  async createPaypalMethod() {
    await this.createPayPalMethod().then((payload) => this.saveToServer(payload)).catch((err) => {
      ElMessage({
        type: 'warning',
        message: h(
          'p',
          { style: 'font-size: 16px; color: red;' },
          err.message
        )
      })
    })
  }

  async createPayPalMethod() {
    return new Promise((resolve, reject) => {
      this.paypalInstance.tokenize(
        {
          flow: 'vault',
          // For more tokenization options, see the full PayPal tokenization documentation
          // https://braintree.github.io/braintree-web/current/PayPal.html#tokenize
        },
        async function (tokenizeErr: any, payload: any) {
          if (tokenizeErr) {
            if (tokenizeErr.type !== 'CUSTOMER') {
              console.error('Error tokenizing:', tokenizeErr)
            }
            console.log(tokenizeErr);
            
            return reject(tokenizeErr)
          }

          // Tokenization succeeded
          console.log(payload)

          console.log('Got a nonce! You should submit this to your server.')
          if (payload.nonce) {
            console.log(payload.nonce)
            return resolve(payload)
          }
        }
      )
    })
  }

  async saveToServer(payload: any) {
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Result
    res = await axios.put(
      'user/update-card',
      {
        nonce: payload.nonce,
        type: 'new',
        cardholder_name:
          payload.details.firstName + ' ' + payload.details.lastName
      },
      {
        headers: {
          'token-user': localStorage.getItem('tokenUser'),
          account: localStorage.getItem('account')
        }
      }
    )

    if (res.status) {
      await this.$store.dispatch('setLoading', false, { root: true })
      ElMessage({
        type: 'success',
        message: h(
          'p',
          { style: 'font-size: 16px; color: green;' },
          'New card added successfully !!!'
        )
      })
      await this.getListCards()
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }

  handleConfirm() {
    this.$router.push({ name: 'DashboardPage' })
  }
}
